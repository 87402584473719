<template>
  <div style="background-color: #f8fcff;">
    <el-card style="width: 62.5%;margin: 20px auto;" class="box-card">
      <div slot="header" class="clearfix" style="overflow:hidden;">
        <span style="font-size: 18px;float:left;">企业注册</span>
        <p class="otherRefister">
          <span @click="clickOtherRefister('/register/register')">
            &gt;&gt;&#160;&#160;&#160;&#160;返回注册车主用户
          </span>
        </p>
      </div>
      <div class="enterPriseRegister">
        <el-form :rules="rules" ref="form" :model="form" label-width="100px">
          <el-form-item prop="userName" style="width: 420px;" label="用户名:">
            <el-input placeholder="请输入手机号" v-model="form.userName"></el-input>
          </el-form-item>
          <el-form-item prop="phoneCode" style="width: 420px;" label="手机验证码:">
            <el-col :span="16">
              <el-input placeholder="请输入手机验证码" v-model="form.phoneCode"></el-input>
            </el-col>
            <el-col :span="8">
              <el-button class="phoneCode" :disabled="disabled" @click="GetPhoneCode">{{ phoneStr }}</el-button>
            </el-col>
          </el-form-item>
          <el-form-item prop="name" style="width: 420px;" label="法人:">
            <el-input placeholder="请输入法人" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item prop="password" style="width: 420px;" label="密码:">
            <el-input placeholder="请输入密码" type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item prop="confirmPassword" style="width: 420px;" label="再次输入密码:">
            <el-input placeholder="请再次输入密码" type="password" v-model="form.confirmPassword"></el-input>
          </el-form-item>
          <el-form-item prop="enterprise" style="width: 420px;" label="公司名称:">
            <el-input placeholder="公司名称需要与营业执照保持一致" v-model="form.enterprise"></el-input>
          </el-form-item>
          <el-form-item prop="email" style="width: 420px;" label="公司邮箱:">
            <el-input placeholder="请输入邮箱" v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item prop="dutyParagraph" style="width: 420px;" label="公司税号:">
            <el-input placeholder="请输入税号" v-model="form.dutyParagraph"></el-input>
          </el-form-item>
          <el-form-item style="width: 420px;" label="公司所属地区:" prop="area">
            <el-cascader v-model="form.area" :options="options"></el-cascader>
          </el-form-item>
          <el-form-item style="width: 420px;" label="详细地址:" prop="address">
            <el-input placeholder="请输入详细地址" v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item style="width: 420px;" prop="contactNumber" label="联系电话:">
            <el-input placeholder="请输入联系电话，例如（010）xxx，010-xxx，xxx" v-model="form.contactNumber"></el-input>
          </el-form-item>
          <el-form-item label="营业执照:">
            <div>
              <el-upload :multiple="false" :accept="uploadaccept" class="avatar-uploader" :action="uploadurl"
                :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <p style="font-size: 14px;color: #999999;">
              注：公司名称需要和营业执照上的保持一致（格式支持：jpg / jpeg /
              png，小于2M）
            </p>
          </el-form-item>
          <el-form-item class="enterRegisterTextarea" prop="content" style="width: 100%;" label="公司介绍:">
            <el-input style="width:57%;" type="textarea" placeholder="请详细填写公司的主要业务，包括业务内容、业务范围等信息。"
              v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="checked">我已阅读并同意</el-checkbox><span style="color: #24A2AA;cursor: pointer;"
              @click="dialogVisible = true">《用户协议手册》</span>
          </el-form-item>
          <el-form-item>
            <el-button @click="onSubmit"
              style="width: 120px;height: 40px;background: #24A2AA;color: white;font-size: 16px;" size="small">
              注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-dialog :close-on-click-modal="false" title="用户协议手册" :visible.sync="dialogVisible">
      <Agreement></Agreement>
    </el-dialog>
  </div>
</template>

<script>
import Agreement from "../register/agreement";
import { permissionMixin } from "@/mixin";
import { mapState } from "vuex";
export default {
  name: "adressMaintain",
  components: {
    Agreement,
  },
  mixins: [permissionMixin],
  computed: {
    ...mapState({
      check: (state) => {
        return state.enterpriseRegister.check;
      },
      isPhoneCode: (state) => {
        return state.enterpriseRegister.isPhoneCode;
      },
      registerSuccess: (state) => {
        return state.enterpriseRegister.registerSuccess;
      },
      get_AreaList: (state) => {
        return state.enterpriseRegister.get_AreaList;
      },
    }),
    uploadurl: function () {
      return process.env.VUE_APP_API_BASE_URL + "api/File/UploadLicense";
    },
  },
  data() {
    var checkUserName = (rule, value, callback) => {
      if (value === undefined) {
        value = "";
      }
      this.$store
        .dispatch("enterpriseRegister/checkPhone", { name: value })
        .then(() => {
          if (this.check) {
            callback();
            return;
          } else {
            callback("此手机号已注册过");
          }
        });
    };
    var checkPassWord = (rule, value, callback) => {
      if (value === undefined) {
        value = "";
      }
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
        return;
      }
    };
    return {
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        phoneCode: [
          {
            required: true,
            message: "请输入手机验证码",
            trigger: "blur",
          },
        ],
        userName: [
          {
            required: true,
            message: "请输入11位手机号码",
            trigger: "blur",
          },
          { pattern: /^1[34578]\d{9}$/, message: "请输入正确手机号" },
          { validator: checkUserName, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            message: "密码6~20位",
            trigger: "blur",
          },
          {
            max: 20,
            message: "密码6~20位",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            message: "密码6~20位",
            trigger: "blur",
          },
          {
            max: 20,
            message: "密码6~20位",
            trigger: "blur",
          },
          { validator: checkPassWord, trigger: "blur" },
        ],
        enterprise: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "请输入正确的邮箱",
          },
        ],
        dutyParagraph: [
          {
            required: true,
            message: "请输入税号",
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "请选择所属地区",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        contactNumber: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请填写公司介绍",
            trigger: "blur",
          },
        ],
      },
      checked: false,
      uploadaccept: ".jpg,.jpeg,.png",
      imageUrl: "",
      fileDataId: 0,
      form: {
        userName: "",
        phoneCode: "",
        name: "",
        password: "",
        confirmPassword: "",
        enterprise: "",
        email: "",
        dutyParagraph: "",
        area: [],
        address: "",
        contactNumber: "",
        content: "",
      },
      options: [],
      dialogVisible: false,
      phoneStr: "发送短信验证码",
      disabled: false,
      timeStr: 60,
    };
  },
  mounted() {
    this.$store.dispatch("enterpriseRegister/GetAreaList", {}).then(() => {
      this.options = this.get_AreaList;
    });
  },
  methods: {
    GetPhoneCode() {
      var phone = this.form.userName;
      const reg = /^1[34578]\d{9}$/;
      if (reg.test(phone)) {
        this.$store
          .dispatch("enterpriseRegister/GetPhoneCode", { phone: phone })
          .then(() => {
            if (!this.isPhoneCode) {
              this.$message.error("此手机号已注册过");
            } else {
              this.$message.success("验证码已发送");
              this.disabled = true;
              this.phoneStr = this.timeStr;
              this.updatetimeStr();
            }
          });
      } else {
        this.$message.error("请输入正确的手机号");
      }
    },
    updatetimeStr() {
      var that = this;
      setTimeout(function () {
        that.timeStr -= 1;
        that.phoneStr = that.timeStr;
        if (that.timeStr == 0) {
          that.timeStr = 60;
          that.disabled = false;
          that.phoneStr = "发送短信验证码";
        } else {
          that.updatetimeStr();
        }
      }, 1000);
    },
    clickOtherRefister(url) {
      let routeUrl = this.$router.push({
        path: url,
      });
    },
    handleAvatarSuccess(res, file) {
      this.fileDataId = res.result.fileId;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    onSubmit() {
      var that = this;
      that.$refs["form"].validate((valid) => {
        if (valid) {
          if (that.checked) {
            if (that.fileDataId < 1) {
              that.$message.error("请上传营业执照");
              return false;
            }
            that.$store
              .dispatch("enterpriseRegister/RegisterEnterprise", {
                UserName: that.form.userName,
                Name: that.form.name,
                Password: that.form.password,
                Enterprise: that.form.enterprise,
                Email: that.form.email,
                DutyParagraph: that.form.dutyParagraph,
                ContactNumber: that.form.contactNumber,
                FileDataId: that.fileDataId,
                AreaId: that.form.area[2],
                Address: that.form.address,
                Content: that.form.content,
                PhoneCode: that.form.phoneCode,
              })
              .then(() => {
                if (that.registerSuccess.success) {
                  that.$message.success("注册成功");
                  that.$router.push({
                    path: "/register/login",
                  });
                  // that.$router.go(-1);
                } else {
                  that.$message.error(that.registerSuccess.msg);
                }
              });
          } else {
            that.$message.error("请同意用户协议手册");
            return false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.enterPriseRegister {
  .phoneCode {
    padding-right: 10px;
    padding-left: 5px;
    margin-left: 2px; /* no */
    background: #24a2aa;
    color: #fff;
    width: 100%;
    text-align: center;
  }
}

.enterRegisterTextarea {
  /deep/.el-textarea__inner::-webkit-input-placeholder {
    letter-spacing: 1px;
  }
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-upload {
  background-color: #eeeeee;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
/deep/.el-textarea__inner {
  letter-spacing: -2px;
}
.otherRefister {
  text-align: right;
  float: right;
  line-height: 25px;
  color: rgb(36, 162, 170);
  cursor: pointer;
}
</style>
